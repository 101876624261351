import React from "react";
import "./faq.css";
import Accordion from "@mui/material/Accordion";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Faq = ({ accordionContent = [] }) => {
  const ismobile = window.innerWidth <= 768;

  return (
    <section className="faq-container">
      <p className="title">
        Frequently <span className="highlighted-text">Asked Questions</span>
      </p>

      {accordionContent.map(({ title, content }, i) => (
        <Accordion
          className="accordion"
          disableGutters
          key={i}
          sx={{
            backgroundColor: "transparent",
            maxWidth: ismobile ? "100%" : "70%",
            display: "block",
            margin: "auto",
            textAlign: "left",
            borderTop: i === 0 ? "none" : "3px solid rgba(234, 240, 255, 1)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${i}-content`}
            id={`panel${i}-header`}
          >
            {title}
          </AccordionSummary>
          <AccordionDetails className="accordion-detail">
            {content}
          </AccordionDetails>
        </Accordion>
      ))}
    </section>
  );
};
export default Faq;
