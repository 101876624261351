import React from "react";
import "./navbar.css";
import Logo from "../../assets/locumpe_logo.svg";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <header className="navbar">
      <div className="navbar-content">
        <Link to={"/"}>
          <img src={Logo} alt="LocumPe Logo" className="logo" />
        </Link>
        <nav className="nav">
          <ul>
            <Link to="/" className="Home">
              Home
            </Link>
            <Link to="/features" className="Features">
              Features
            </Link>
            <Link to="/about" className="About">
              About
            </Link>
            <Link to="/works" className="works">
              How it works
            </Link>
            <Link to="/partner" className="partner">
              Partner Login
            </Link>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
