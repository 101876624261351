import React from "react";
import "./onboarding.css";
// import Logo from "../../assets/locumpe_logo.svg";
// import HeroImage from "../../assets/heroImage.webp";
import WIP from "../../assets/WIP.png";
import Navbar from "../Navbar/Navbar";

const Onboarding = () => {
  return (
    <>
      <Navbar />
      <section className="onboarding-wrapper">
        {/* <img src={Logo} alt="logo" />
        <section className="onboarding-content-container">
          <div className="onboarding-details-container"></div>
          <img src={HeroImage} alt="HeroImage" />
        </section> */}

        <img
          src={WIP}
          alt="WIP"
          style={{ width: "40%", display: "block", margin: "auto" }}
        />
      </section>
    </>
  );
};

export default Onboarding;
