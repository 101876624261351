import React, { useState } from "react";
import "./homepage.css";
import Faq from "../FAQ/Faq";
import LogoLarge from "../../assets/logo_large.png";
import BlackChevron from "../../assets/black_chevron.svg";
import iPhoneLeft from "../../assets/iPhoneLeft.png";
import iPhoneRight from "../../assets/iPhoneRight.png";
import HeroImage from "../../assets/heroImage.webp";
import HeroImageMob from "../../assets/heroImageMob.webp";
import Docs from "../../assets/doctors.webp";
import AppBenefits from "../../assets/AppBenefits.webp";
import AppBenefitsMob from "../../assets/AppBenefits.png";
import StepImage from "../../assets/stepsImg.png";
import StepImageMob from "../../assets/stepsImgMob.png";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const HomePage = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const ismobile = window.innerWidth <= 768;

  let accordionContent = [
    {
      title: "How long will it take to verify my profile?",
      content: "Verification is completed within 24 hours of submission. ",
    },
    {
      title: "How do I get my payment after the shift?",
      content:
        "Payments are processed immediately after duty completion and confirmation by the recruiter. ",
    },
    {
      title: "Can I cancel my shift at the last minute?",
      content:
        "Last-minute cancellations are discouraged and might affect your profile score, but can be managed through the app. ",
    },
  ];

  let tabContent = [
    {
      label: "01",
      title: "Register",
      description:
        "Sign up with your credentials and get a verified profile. Provide your professional details and necessary documents securely.",
    },
    {
      label: "02",
      title: "Find & Book",
      description:
        "Find locum jobs near you that match your specialty and preferences. Book shifts that fit your schedule.",
    },
    {
      label: "03",
      title: "Get Paid Immediately",
      description:
        "Complete your duty and receive payment within minutes. With LocumPe, you never have to worry about underpayments or delays.",
    },
  ];

  const onboardingNavigator = () => navigate("/onboarding");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ marginTop: ismobile ? "20px" : "33px" }}>{children}</Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <>
      <Navbar />
      <section className="hero-section">
        <div className="hero-text">
          <p className="hero-title">
            Easily find convenient
            <span className="highlighted-text"> Locum Duties</span>
            <br /> around you!
          </p>
          <p className="hero-description">
            LocumPe is your trusted platform for finding convenient locum jobs
            that fit your schedule and pay you immediately after job completion.
          </p>
          <button className="join-waitlist-btn" onClick={onboardingNavigator}>
            Join Waitlist
          </button>
        </div>

        <img
          src={ismobile ? HeroImageMob : HeroImage}
          alt="HeroImage"
          className="hero-image"
        />
      </section>

      <section className="how-it-works">
        <div>
          <p className="title">
            How it works
            <br />
            <span className="highlighted-text">3 Easy Steps</span>
          </p>
          <Tabs className="step-tabs" value={value} onChange={handleChange}>
            {tabContent.map(({ label }, i) => (
              <Tab label={label} key={i} />
            ))}
          </Tabs>

          {tabContent.map(({ title, description }, i) => (
            <CustomTabPanel value={value} index={i} key={i}>
              <p className="content-title">{title}</p>
              <p className="content-description">{description}</p>
            </CustomTabPanel>
          ))}
        </div>

        <img
          className="stepImage"
          src={ismobile ? StepImageMob : StepImage}
          alt="StepImage"
        />
      </section>

      <section className="third-section">
        <img
          src={ismobile ? AppBenefitsMob : AppBenefits}
          alt="AppBenefits"
          className="img-benefits"
        />
        <div className="join-waitlist-container">
          <div>
            <p className="title">
              Join the <span className="highlighted-text">Waitlist</span>
            </p>
            {ismobile ? (
              <p className="description">
                Be among the first to experience <br /> LocumPe. Sign up now to
                join the waitlist <br /> and get early access to verified locum{" "}
                <br /> jobs and immediate payments.
              </p>
            ) : (
              <p className="description">
                Be among the first to experience LocumPe.
                <br /> Sign up now to join the waitlist and get early access to
                verified locum jobs and immediate payments.
              </p>
            )}
            <button className="join-waitlist-btn" onClick={onboardingNavigator}>
              Join Waitlist
            </button>
          </div>
          <img alt="doctors" src={Docs} className="docs" />
        </div>
      </section>

      <section className="UI-container">
        <div className="simple-UI-section">
          <img className="iPhoneLeft" alt="iPhoneLeft" src={iPhoneLeft} />
          <div className="middle-content">
            <p className="small">
              Simple <br />
              <span className="large"> User Interface</span>
            </p>
            <p className="small-desc">
              Our user interface is designed to be simple and intuitive, making
              it easy for doctors to find and book locum jobs.
            </p>
          </div>
          <img className="iPhoneRight" alt="iPhoneRight" src={iPhoneRight} />
        </div>
      </section>

      <Faq accordionContent={accordionContent} />

      <section className="about">
        <div className="about-content-container">
          <p className="about-title">
            About <span className="highlighted-text">LocumPe</span>
          </p>
          <p className="about-description">
            LocumPe is a tech-powered platform designed for doctors to find
            locum duties that fit their schedule and get paid immediately. Our
            secure and efficient system ensures hassle-free job placements and
            timely payments.
          </p>
          <div className="waitlist-text-container">
            <p className="join-text" onClick={onboardingNavigator}>
              Join waitlist now
            </p>
            <img
              src={BlackChevron}
              alt="BlackChevron"
              className="BlackChevron"
            />
          </div>

          <img className="logo-large" src={LogoLarge} alt="LogoLarge" />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default HomePage;
