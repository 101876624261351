import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/locumpe_logo_inverted.svg";
import Chevron from "../../assets/white_chevron.svg";

const Footer = () => (
  <footer className="footer">
    <div className="left-footer">
      <Link to={"/"}>
        <img src={Logo} alt="LocumPe Logo" className="logo" />
      </Link>
      <p>
        LocumPe is a tech-powered platform designed for doctors to find locum
        duties that fit their schedule and get paid immediately. Our secure and
        efficient system ensures hassle-free job placements and timely payments.
      </p>
    </div>
    <div className="right-footer">
      <p>Social Links</p>
      <ul>
        <li>
          <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
            Facebook
            <img src={Chevron} alt="chevron" />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
            Instagram
            <img src={Chevron} alt="chevron" />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
            {" "}
            Youtube
            <img src={Chevron} alt="chevron" />
          </a>
        </li>
        <li>
          <a href="mailto:locumpe@gmail.com">
            locumpe@gmail.com
            <img src={Chevron} alt="chevron" />
          </a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
