import "./App.css";
import "./fonts.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./components/Homepage/HomePage";
import Onboarding from "./components/Onboarding/Onboarding";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/features" element={<HomePage />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/about" element={<HomePage />} />
        <Route path="/works" element={<HomePage />} />
        <Route path="/partner" element={<HomePage />} />
      </Routes>
    </div>
  );
}

export default App;
